/* src/components/styles/OpdsInfo.css */
.opds-info {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.opds-info h2 {
    color: #333;
}

.opds-info p {
    color: #666;
}

.opds-info code {
    display: block;
    background-color: #eee;
    padding: 10px;
    border-radius: 3px;
    margin-top: 10px;
    color: #333;
}